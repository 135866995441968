import React from "react";
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

export default function FooterComponent({ setSubmitted }) {


  return (
    <div>
      <div className="footer">
        <div className="container-xl pt-3">
          <div className="row">
            <div className="col-md-6 flex-around">
              <p>Global Match B.V.</p>
              <p>Muntstraat 13H</p>
            </div>
            <div className="col-md-6 flex-around">
              <p>4903PH Oosterhout</p>
              <p><a href="mailto:info@globalmatch.nl">info@globalmatch.nl</a></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
